<template>
  <div>
    <div class="header">
      <div class="t">
        <img @click="go" src="../assets/z1.png" alt="" /><span
          >居转户条件评定</span
        >
      </div>
      <div class="d">
        请在符合您情况的选项内搭勾 <br />或在文本框中填写相应的数字<br />与内容
      </div>
    </div>
    <div class="content">
      <div class="title">基础指标和分值</div>
      <!-- //1 -->
      <div class="item1">
        <div class="subject">1、持有《上海居住证》年限</div>
        <div class="content1">
          持证已满
          <input
            v-model="form.radio1"
            type="number"
            style="
              margin: 0 0.2rem;
              width: 1.46rem;
              background: #ffffff;
              border: 0.02rem solid #d9d9d9;
            "
          />年
        </div>
      </div>
      <van-divider />
      <!-- //2 -->
      <div class="item1">
        <div class="subject">2、持证期间按照规定参加本市城镇社会保险时间</div>
        <div class="content1">
          本市缴纳社保已满
          <input
            type="number"
            style="
              margin: 0 0.2rem;
              width: 1.46rem;
              background: #ffffff;
              border: 0.02rem solid #d9d9d9;
            "
          />月，缴费基数
          <div class="Picker" @click="show = true">
            请选择：{{ form.radio2 }}
          </div>
        </div>
      </div>
      <van-divider />
      <!-- //3 -->
      <div class="item1">
        <div class="subject">3、持证期间本市依法缴纳个人所得税</div>
        <div class="content1">
          <van-radio-group v-model="form.radio3">
            <van-radio
              icon-size=".24rem"
              name="对应社保缴费基数比例"
              class="btn"
              >对应社保缴费基数比例</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="高于社保缴费基数比例"
              class="btn"
              >高于社保缴费基数比例</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="低于社保缴费基数比例"
              class="btn"
              >低于社保缴费基数比例</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //4 -->
      <div class="item1">
        <div class="subject">4、专业技术职称和技能等级</div>
        <div class="content1">
          <van-radio-group v-model="form.radio4">
            <van-radio
              icon-size=".24rem"
              name="无专业技术职称和技能等级"
              class="btn"
              >无专业技术职称和技能等级</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="有二级或以上技能等级"
              class="btn"
              >有二级或以上技能等级</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="有中级或以上专业技术职称"
              class="btn"
              >有中级或以上专业技术职称</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="可聘中级专业技术职务任职资格"
              class="btn"
              >可聘中级专业技术职务任职资格</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //5 -->
      <div class="item1">
        <div class="subject">
          5、违反国家及本市计划生育政策规定行为、治安
          管理处罚以上违法犯罪记录及其他方面的不良行为记录
        </div>
        <div class="content1">
          <van-radio-group v-model="form.radio5">
            <van-radio icon-size=".24rem" name="无" class="btn">无</van-radio>
            <van-radio
              icon-size=".24rem"
              name="有未婚先孕、超生行为"
              class="btn"
              >有未婚先孕、超生行为</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="有行政处罚、违法犯罪记录"
              class="btn"
              >有行政处罚、违法犯罪记录</van-radio
            >
            <van-radio
              icon-size=".24rem"
              name="有未撤销的高等教育院校处分,党纪处分记录"
              class="btn"
              >有未撤销的高等教育院校处分,党纪处分记录</van-radio
            >
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="content" style="margin-top: 0.5rem; margin-bottom: 50px">
      <div class="title">激励条件</div>
      <!-- //6 -->
      <div class="item1">
        <div class="subject">6、就职单位资质或性质</div>
        <div class="content1">
          <van-radio-group v-model="form.radio6">
            <van-radio icon-size=".24rem" name="普通民营企业" class="btn"
              >普通民营企业</van-radio
            >
            <van-radio icon-size=".24rem" name="国有企业" class="btn"
              >国有企业</van-radio
            >
            <van-radio icon-size=".24rem" name="外资企业" class="btn"
              >外资企业</van-radio
            >
            <van-radio icon-size=".24rem" name="科技企业" class="btn"
              >科技企业</van-radio
            >
            <van-radio icon-size=".24rem" name="高新技术认定企业" class="btn"
              >高新技术认定企业</van-radio
            >
          </van-radio-group>
        </div>
      </div>
      <van-divider />
      <!-- //7 -->
      <div class="item1">
        <div class="subject">7、重大贡献获奖或高级技术职称、职业资格证书</div>
        <div class="content1">
          <van-radio-group v-model="form.radio7">
            <van-radio icon-size=".24rem" name="否" class="btn">否</van-radio>
            <van-radio
              icon-size=".24rem"
              name="有《一级高级技师》或聘《高级专业技术职务》"
              class="btn"
              >有《一级高级技师》或聘《高级专业技术职 务》</van-radio
            >
          </van-radio-group>
        </div>
        <div class="js">
          *在本市作出重大贡献并获得相应奖励,或者在本
          市被评聘为高级专业技术职务或者高级技师(国
          家一级职业资格证书)且专业、工种与所聘岗位
          相对应的,可以不受居住证持证年限、社保缴纳
          时间要求限制;【重大贡献奖项范围标准,由相 关管理部门适时公布】
        </div>
      </div>
      <van-divider />
      <!-- //8 -->
      <div class="item1">
        <div class="subject">8、远郊公共服务领域</div>
        <div class="content1">
          <van-radio-group v-model="form.radio8">
            <van-radio icon-size=".24rem" name="否" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="是" class="btn">是</van-radio>
          </van-radio-group>
        </div>
        <div class="js">
          *在本市远郊地区的教育、卫生等岗位工作满5年
          的,持证及参保年限可以缩短至5年;
        </div>
      </div>
      <van-divider />
      <!-- //9-->
      <div class="item1">
        <div class="subject">9、多倍社保与计税薪酬</div>
        <div class="content1">
          <van-radio-group v-model="form.radio9">
            <van-radio icon-size=".24rem" name="否" class="btn">否</van-radio>
            <van-radio icon-size=".24rem" name="是" class="btn">是</van-radio>
          </van-radio-group>
        </div>
        <div class="js">
          *最近连续3年在本市缴纳城镇社会保险基数高于
          本市上年度职工平均工资2倍以上,或者最近连
          续3年计税薪酬收入高于上年同行业中级技术、
          技能或者管理岗位年均薪酬收入水平的技术管理
          和关键岗位人员可以不受(专业技术职务或者职
          业资格等级)的限制;【计税薪酬收入标准,由 相关管理部门适时公布
        </div>
      </div>
      <van-divider />
      <!-- //10-->
      <div class="item1">
        <div class="subject">10、投资纳税或带动本地就业</div>
        <div class="content1">
          <van-radio-group disabled>
            <van-radio icon-size=".24rem" name="否" class="btn"
              >由相关管理部门公布标准</van-radio
            >
          </van-radio-group>
        </div>
        <div class="js">
          *按照个人在本市直接投资(或者投资份额)计
          算,最近连续3个纳税年度累计缴纳总额及每年
          最低缴纳额达到本市规定标准,或者连续3年聘
          用本市员工人数达到规定标准的相关投资和创业
          人才,可以不受【专业技术职务或者职业资格等 级】的限制;
        </div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom">
      <van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
    <div class="btn1" @click="btn">提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      columns: [
        "低于上海市上年度职工平均工资80%",
        "高于上海市上年度职工平均工资80%低于1倍",
        "高于上海市上年度职工平均工资1倍低于2倍",
        "高于上海市上年度职工平均工资2倍低于3倍",
        "高于上海市上年度职工平均工资3倍",
      ],
      value: "",
      form: {
        radio1: "",
        radio2: "",
        radio3: "",
        radio4: "",
        radio5: "",
        radio6: "",
        radio7: "",
        radio8: "",
        radio9: "",
      },
    };
  },
  methods: {
    go() {
      this.$router.push("/instrument");
    },
    onConfirm(value, index) {
      this.form.radio2 = value;
      this.show = !this.show;
    },
    onCancel() {
      this.show = !this.show;
    },
    btn() {
      let flag = false;
      for (const key in this.form) {
        if (this.form[key] == "") {
          this.$toast.fail("请填完整表单");
        }
      }
      // 第一题判定条件
      if (this.form.radio1) {
        flag = Number(this.form.radio1) > 0;
      } else {
        flag = false;
      }
      //第二题判定条件
      if (
        this.form.radio2 == "低于上海市上年度职工平均工资80%" ||
        this.form.radio2 == "高于上海市上年度职工平均工资80%低于1倍"
      ) {
        flag = false;
      } else {
        flag = true;
      }
      // 第三题判定条件
      if (this.form.radio3) {
        flag = true;
      }
      // 第四题判定条件
      if (this.form.radio4 == "无专业技术职称和技能等级") {
        flag = false;
      } else {
        flag = true;
      }
      // 第五题判定条件
      if (this.form.radio5) {
        flag = true;
      }
      // 第六题判定条件
      if (this.form.radio6) {
        flag = true;
      }
      // 第七题判定条件
      if (this.form.radio7 == "否") {
        flag = false;
      } else {
        flag = true;
      }
      // 第八题判定条件
      if (this.form.radio8 == "否") {
        flag = false;
      } else {
        flag = true;
      }
      // 第九题判定条件
      if (this.form.radio9 == "否") {
        flag = false;
      } else {
        flag = true;
      }
      console.log(flag);
      console.log(this.form);

      this.$router.push({
        path: "/condition",
        query: {
          flag: flag,
        },
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.header {
  overflow: hidden;
  height: 4.5rem;
  background: url("../assets/userimg.jpg") no-repeat;
  background-size: 100%;
  .t {
    margin-top: 0.3rem;
    margin-left: 0.2rem;
    img {
      width: 0.4rem;
      margin-right: 0.5rem;
    }
    display: flex;
    align-items: center;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .d {
    margin-top: 0.2rem;
    margin-left: 0.7rem;
    font-size: 0.34rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.content {
  margin-top: -1.5rem;
  overflow: hidden;
  padding: 0 0.2rem;
  box-sizing: border-box;
  position: relative;
  width: 6.7rem;
  margin-left: 0.45rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.12rem 0rem rgba(156, 156, 156, 0.23);

  border-radius: 0.1rem;
  .title {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 0.56rem;
    width: 3.34rem;
    height: 0.56rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;

    background: #4875cb;
    border-radius: 0px 0px 0.5rem 0.5rem;
  }
  .item1 {
    .js {
      margin-left: 0.4rem;
      margin-bottom: 0.2rem;
      font-size: 0.22rem;
      color: #cdcdcd;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .subject {
      margin: 0.2rem 0;
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
    }
    .content1 {
      margin-left: 0.6rem;
      margin-bottom: 0.2rem;
      font-size: 0.26rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
    }
  }
}
.btn {
  margin-bottom: 0.2rem;
}
.btn1 {
  margin-left: 0.32rem;
  margin-bottom: 0.3rem;
  text-align: center;
  line-height: 0.7rem;
  width: 6.86rem;
  height: 0.7rem;
  background: linear-gradient(90deg, #458aec 0%, #6ab4fd 100%);
  border-radius: 0.48rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.Picker {
  padding: 0 0.2rem;
  box-sizing: border-box;
  margin-top: 0.2rem;
  line-height: 0.48rem;
  width: 5rem;
  height: 0.48rem;
  background: #ffffff;
  border: 0.02rem solid #d9d9d9;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>